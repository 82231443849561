<template>
    <Page icon="mdi-lifebuoy" :title="$t('routes.helprequests')" :progress="progress">
        <ActionBar @filter="s => load(s)" :searchProgress="searchProgress" />

        <v-tabs class="mt-3">
            <v-tab @click="tab = 0; tabChange()">{{$t('helprequest.tabs.waiting')}}</v-tab>
            <v-tab @click="tab = 1; tabChange()">{{$t('helprequest.tabs.accepted')}}</v-tab>
            <v-tab @click="tab = 2; tabChange()">{{$t('helprequest.tabs.closed')}}</v-tab>
        </v-tabs>
            

        <v-simple-table>
            <thead>
                <tr>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('helprequest.list.id')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="counter"
                            type="numeric"
                        />
                    </th>
                    <th class="primary--text">
                        {{ $t('helprequest.list.subject') }}
                    </th>
                    <th class="primary--text">
                        <SortHeader 
                            :text="$t('helprequest.list.created_at')"
                            v-model="form.sort"
                            @change="load(form.filter)"
                            field="createdAt"
                            type="date"
                        />
                    </th>
                    <th v-if="tab > 0" class="primary--text">
                        {{ $t('helprequest.list.user') }}
                    </th>
                    <th class="w-action primary--text">
                        {{ $t('helprequest.list.action' )}}
                    </th>
                </tr>
            </thead>

            <tbody style="white-space: nowrap;">
                <tr v-for="(item, index) in helprequests" :key="index">
                   <td>
                       <span>
                            <v-icon left>mdi-music-accidental-sharp</v-icon>{{ item.counter }}
                       </span>
                   </td>
                   <td>
                       <span>
                            <v-icon left>mdi-clipboard-text-outline</v-icon>{{ item.subject }}
                       </span>
                   </td>
                   <td>
                       <span>
                            <v-icon>mdi-calendar</v-icon>
                            {{ (new Date(item.createdAt)).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric"}) }}
                            {{ (new Date(item.createdAt)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit' }) }}
                            
                       </span>
                   </td>
                   <td v-if="tab > 0">
                       <span>
                           
                            <v-icon>mdi-account</v-icon>
                            {{ item.users[0] ? item.users[0].name : null }}
                            
                       </span>
                   </td>
                    <td class="w-action">
                        <v-btn
                            isabled
                            fab
                            color="primary"
                            x-small
                            dark
                            depressed
                            :to="{ name: 'helprequest', params: { id: item._id, routefrom: 'helprequests' } }"
                        >
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </td>

                </tr>
            </tbody>
        </v-simple-table>

        <v-pagination
            class="mt-3"
            @input="load(form.filter)"
            v-if="pagination.total > 1"
            v-model="form.page"
            :length="pagination.total"
            :total-visible="7"
        />

    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import ActionBar from "./components/ActionBar.vue";
import SortHeader from "./components/SortHeader.vue";

export default {
    components: {
        ActionBar,
        Page,
        SortHeader

    },

    async mounted() {
        this.load();
    },

    data: () => ({
        progress: true,
        searchProgress: false,
        dialog: false,

        pagination: {
            total: 0,
            filter: null
        },
        
        form: {
            sort: "-createdAt -counter ",
            page: 1,
            limit: null,
            filter: null,
            accepted: false,
            closed: false
        },

        helprequests: null,
        tab: null,
        
    }),
    
    computed: {
        user() {
            return this.$store.state.api.user.data;
        }
    },

    methods: {
        async load(filter) {
            if (filter) this.form.filter = filter;
            else this.form.filter = null;

            if(this.pagination.filter != filter) this.form.page = 1;
            this.pagination.filter = filter;
            
            this.searchProgress = !this.progress;

            this.selectedEntity();

            const res = await this.$store.dispatch("api/helprequest/all", this.form);

            if (res.task.count == 0) {
                this.progress = false;
                this.searchProgress = false;

                if(res.status == 200) {
                    this.helprequests = res.data;
                    this.pagination.total = res.pages;
                }
            }
        },

        tabChange(){
            if ( this.tab == 0){
                this.form.accepted = false;
                this.form.closed = false;
            } else if ( this.tab == 1) {
                this.form.accepted = true;
                this.form.closed = false;
            } else {
                this.form.accepted = true;
                this.form.closed = true;
            }

            this.load(this.form.filter);
        },

        selectedEntity(){
            let entity = this.$store.state.api.user.selectedEntity;
            if(!entity) return null;
            this.form.entity = entity._id;
            return entity._id;
        }
    }
};
</script>
